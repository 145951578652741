import Brand1 from "../images/brand1.png";
import Brand2 from "../images/brand2.png";
import Brand3 from "../images/brand3.png";
import Brand4 from "../images/brand4.png";
import Brand5 from "../images/brand5.png";
import Brand6 from "../images/brand6.png";
import Brand7 from "../images/brand7.png";
import Brand8 from "../images/brand8.png";

export const Brands = [
    {
      id: 1,
      src: Brand1
    },
    {
      id: 2,
      src: Brand2
    },
    {
      id: 3,
      src: Brand3
    },
    {
      id: 4,
      src: Brand4
    },
    {
      id: 5,
      src: Brand5
    },
    {
      id: 6,
      src: Brand6
    },
    {
      id: 7,
      src: Brand7
    },
    {
      id: 8,
      src: Brand8
    }
  ];
